$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
				$pageWidth = $(window).width(),
				$headerHeight = $('header#header').outerHeight(),
				$footerHeight = $('footer#footer').outerHeight();

		mainContainer($pageHeight, $headerHeight, $footerHeight);
		mobileMenu();
		searchFilter();
		loader();
		backToTop();
		homePageBanner();
		responsiveSlick();
		requestGallery();
		makeGallery();
		makePaginate();

	});

	$(window).on('resize', function(){
		var $pageHeight = $(window).height(),
				$pageWidth = $(window).width(),
				$headerHeight = $('header#header').outerHeight(),
				$footerHeight = $('footer#footer').outerHeight();
		setTimeout(function(){
			mainContainer($pageHeight, $headerHeight, $footerHeight);
		}, 1000);
	});

});

function mainContainer( $pageHeight = 0, $headerHeight = 0, $footerHeight = 0 ){
	$('#main').css({
		'min-height': $pageHeight + $headerHeight,
		'padding-bottom': $footerHeight,
		'padding-top': $headerHeight
	});
	$('#mobile-menu').css({
		'padding-top': $headerHeight
	});
}

function mobileMenu(){
	$(document).on('click', '.button-mobile', function(){
		$(this).toggleClass('active');
		$('#main').toggleClass('menu-open');
		$('#mobile-menu').toggleClass('active');
		$('#aside-backdrop').toggleClass('active');
		$('#header').toggleClass('mobile-active');
	});
	$(document).on('click', '#aside-backdrop', function(){
		$(this).toggleClass('active');
		$('#main').toggleClass('menu-open');
		$('#mobile-menu').toggleClass('active');
		$('.button-mobile').toggleClass('active');
		$('#header').toggleClass('mobile-active');
	});
	$(document).on('click', '#mobile-menu .mobile ul li.menu-item-has-children a', function(e){
		e.preventDefault();
		var text = $(this).text();
		$('#mobile-menu .mobile ul li').removeClass('active');
		$(this).parent().addClass('active').find('> .sub-menu').addClass('active').find('> .bck-menu .text').text(text);
	});
	if( $('#mobile-menu .mobile ul li').hasClass('menu-item-has-children') ) {
		$('#mobile-menu .mobile ul li.menu-item-has-children > .sub-menu').prepend('<li class="bck-menu"><i class="fal fa-angle-left"></i><span class="text">Back</span></li>')
	}
	$(document).on('click', '#mobile-menu .bck-menu', function(e){
		$(this).parent().removeClass('active');
	});
}

function searchFilter(){
	$(document).on('click', '.button-search', function(){
		$('#search-filter').toggleClass('active');
	});
	$(document).on('click', '.button-close-search', function(){
		$('#search-filter').toggleClass('active');
	});

	var $pageHeight = $(window).height(),
			$searchHeaderHeight = $('#search-filter .search-header').outerHeight();
	$('#search-filter .output-wrapper').css({
		'max-height': $pageHeight - ( $searchHeaderHeight + 40 )
	});
	$('#search-filter .search-loader').hide();

	$("#search-input").bind("change paste keyup", function() {
	  var s = $(this).val();
	  $.ajax({
			url : site_ajax_object.ajaxurl,
			type : 'POST',
			dataType: 'json',
			data : {
				action : 'request_search',
				s : s
			},
			beforeSend: function(){
				$('#search-filter .search-loader').fadeIn();
	  		$('#search-filter .search-message').hide();
	  		$('#search-filter .output-wrapper').empty();
			},
			success: function(response) {
				setTimeout(function(){
					$('#search-filter .search-loader').hide();
					setTimeout(function(){
						$('#search-filter .output-wrapper').html(response.content);
					}, 300);
				}, 300);
			}
		});
	});
}

function loader(){
	$('#loader-overlay').fadeOut(200);
}

function backToTop(){
	$(document).on('click', '#backToTop a', function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});
}

function homePageBanner(){
	setTimeout(function(){
		$('#hp-welcome').typeWritter({
	    animDelay: 100,
	    text: $('.hp-banner #unredered').text()
	  });
	}, 800);
}

$.fn.typeWritter = function(opts){
  var $this = this,
      defaults = {
        animDelay: 50
      },
      settings = $.extend(defaults, opts);
  var letters = settings.text.length;
  var i = 0;
  $this.empty();
  settings.text.split('').forEach(letter => {
  	setTimeout(function(){
  		$this.html($this.text() + letter);
  	}, settings.animDelay * i++);
  });
};

// <div class="slick-responsive" data-breakpoints="1199,4|991,2|659,2|480,1" data-thumb=".sample" data-arrows="true" data-defaultview="4">
function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: $(this).data('dots'),
		  infinite: $(this).data('infinite'),
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function makeGallery(){
	$('.mk-gallery').each(function(){
		$(this).lightGallery({
	    thumbnail: $(this).data('thumbnail'),
	    animateThumb: $(this).data('animateThumb'),
	    showThumbByDefault: $(this).data('showThumbByDefault'),
	    selector: $(this).data('selector')
		}); 
	});
}

function requestGallery(){
	$(document).on('change', '#stay-featured', function(){
		var row = $(this).val(),
				pageID = $(this).data('page');
	  $.ajax({
			url : site_ajax_object.ajaxurl,
			type : 'POST',
			dataType: 'json',
			data : {
				action : 'request_gallery',
				row : row,
				pageID: pageID
			},
			beforeSend: function(){
				$('.stay .featured-images').css({
					'opacity' : '0.5',
					'pointer-events' : 'none'
				});
			},
			success: function(response) {
				$('.stay .featured-images .images').html(response.content);
				$('.stay .featured-images .images.mk-gallery').data('lightGallery').destroy(true);
				setTimeout(function(){
					$('.stay .featured-images').css({
						'opacity' : '1',
						'pointer-events' : 'initial'
					});
					makeGallery();
				}, 300);
			}
		});
	});
}

function template(data) {
	var html = '';
  $.each(data, function(index, item){
    html += item;
  });
  return html;
}

function makePaginate(){
	$('.make-paginate').each(function(){
		var parent = $(this);
		parent.append('<div class="pager"><div class="previousPage"><i class="fal fa-angle-left"></i></div><div class="pageNumbers"></div><div class="nextPage"><i class="fal fa-angle-right"></i></div></div>');
		parent.paginga({
			page: ((parent.attr('data-page')) ? parent.attr('data-page') : 1),
			itemsPerPage: ((parent.attr('data-itemsPerPage')) ? parent.attr('data-itemsPerPage') : 1),
			maxPageNumbers: ((parent.attr('data-maxPageNumbers') == true) ? true : false),
			autoHidePager: ((parent.attr('data-autoHidePager') == true) ? true : false),
			itemsContainer: ((parent.attr('data-itemsContainer')) ? parent.attr('data-itemsContainer') : '.items'),
			item: ( (parent.attr('data-item')) ? parent.attr('data-item') : '.item'),
			nextPage: ".nextPage",
			previousPage: ".previousPage", 
			// firstPage: ".firstPage",
			// lastPage: ".lastPage",
			pageNumbers: ".pageNumbers",
			currentPageClass: "active",
			pager: ".pager"
		});
	});
}