$(document).ready(function(){
	$(window).load(function(){
		animateRibon();

		$(document).on('click', '.custom-accordion', function(){
			var target = $(this).data('target');
			$(this).attr('aria-expanded', function(index, attr){
			    return attr == 'true' ? 'false' : 'true';
			});
    	$(target).toggleClass('collapsing');
    	setTimeout(function(){
    		$(target).toggleClass('collapsing').toggleClass('show');
    	}, 50);
		});

		$(document).on('click', '.createAccount-btn', function(){
			var scrollTop = $( $(this).attr('href') ).offset().top;
      $('html, body').animate({
        scrollTop: scrollTop
      }, 500);
      return false;
		});

	});
});

function animateRibon(){
	$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    $('.home .has-ribbon').each(function(){
    	var offset = $(this).offset().top - ($('#header').height() + 200);
    	var target = $(this).find('.ribbon');
    	if( scroll >= offset ) {
    		target.addClass('reveal');
    	}
    });
	});
}